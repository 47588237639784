import React, { useState } from "react";
import { formsConfigWithKey } from "../../../form/config";
import { tableButtons, tableIcons } from "./static";
import { Alert, Button, Card, Modal, Select, Space, Table, Typography, Divider, Collapse } from "antd";
import { Link } from "react-router-dom";
import FormIndex from "../../../form";
import { Row, Col, Form, Input } from "antd";
import { updateUser } from "../../../../backend/users/set";
import Loading from "../../../../components/ui/loading/Loading";
import { getDefaultStore, useAtom } from "jotai";
import { usersAtom } from "../users.jotai";
import { FaCheck, FaClock, FaX } from "react-icons/fa6";
import { highlightElement } from "../../../../helpers/ui";
import moment from "moment";
import Extra from "./Extra";

const { Text, Title } = Typography;

const getUserFormStatus = (user, form, rawUsers) => {
  if (user[form.key + "_submitted"]) {
    return "FormSubmitted";
  }
  if (rawUsers) {
    const rawUser = rawUsers.find((u) => u.id === "raw-" + user.id + "-" + form.key);
    if (rawUser?.[form.key + "-last_saved_on"]) return "FormStarted";
  }
  if (user[form.key + "-started_on"]) {
    return "FormOpened";
  }
};

const ApproveUser = ({ user, rowData }) => {
  const initialUserSelection = user[rowData.formKey + "-approved"];
  const [approvalStatus, setApprovalStatus] = useState(initialUserSelection);
  const [notes, setNotes] = useState(user.notes);
  const [loading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  // Use the Jotai atom
  const [users, setUsers] = useAtom(usersAtom);

  const handleSelectionChange = (value) => {
    setApprovalStatus(value);
    setIsDirty(true);
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
    setIsDirty(true);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await updateUser(user.id, {
        notes,
        [rowData.formKey + "-approved"]: approvalStatus,
      });

      // Update Jotai atom by mapping over existing users and replacing the updated user
      setUsers((prevUsers) => prevUsers.map((u) => (u.id !== user.id ? u : { ...u, notes, [rowData.formKey + "-approved"]: approvalStatus })));

      setIsDirty(false); // Reset dirty state after successful submission
    } catch (error) {
      console.error("Error updating user:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setApprovalStatus(initialUserSelection);
    setNotes(user.notes); // Reset notes to initial value
    setIsDirty(false);
  };

  if (loading) return <Loading />;

  return (
    <Row gutter={16}>
      {/* Left side with display content */}
      <Col span={16} style={{ maxHeight: "75vh", overflowY: "auto" }}>
        <Alert description={"This is user input"} />
        <FormIndex displayOnly options={{ user, form_name: rowData.formKey }} />
      </Col>

      {/* Right side with Notes and Approval Select */}
      <Col span={8} style={{ borderLeft: "1px dashed darkgrey" }}>
        {/* Notes Section */}
        <Alert description={"Add notes and approve here"} />
        <b strong>Notes</b>
        <Input.TextArea placeholder="Enter notes here..." autoSize={{ minRows: 6 }} value={notes} onChange={handleNotesChange} style={{ marginBottom: "16px" }} />

        {/* Approval Select */}
        <b strong>Status</b>
        <Select
          placeholder="Select status"
          value={approvalStatus}
          onChange={handleSelectionChange}
          options={[
            { value: true, label: "Approved" },
            { value: false, label: "Disapproved" },
          ]}
          style={{ width: "100%", marginBottom: "16px" }}
        />

        {/* Save/Cancel Buttons */}
        {isDirty && (
          <div style={{ display: "flex", gap: "8px" }}>
            <Button type="primary" onClick={handleSubmit}>
              Save
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </div>
        )}
      </Col>
    </Row>
  );
};

const UserFormSubmissions = ({ user, rawUsers }) => {
  const tableData = formsConfigWithKey.map((form, index) => ({
    key: index,
    formTitle: form.title,
    formKey: form.key,
    status: getUserFormStatus(user, form, rawUsers),
    approved: user[form.key + "-approved"],
  }));

  console.log(tableData, "table data");
  // Define columns for the Ant Design Table
  const columns = [
    {
      title: "Form",
      width: "200px",
      dataIndex: "formTitle",
      key: "formTitle",
    },

    {
      title: "Approval Status",
      dataIndex: "approved",
      width: "250px",
      key: "approved",
      render: (approved, rowData) => {
        console.log(rowData);
        const handleOpenApplication = () => {
          Modal.confirm({
            title: "Approve/Disapprove Application",
            width: "100%",
            icon: null,
            content: <ApproveUser user={user} rowData={rowData} />,
            closable: true, // Adds the close button at the top
            maskClosable: true, // Allows the modal to close when clicking outside
            destroyOnClose: true, // Ensures modal content is destroyed on close
            onOk: () => highlightElement(document.querySelector(`[data-row-key='${user.id}']`)),
            onClose: () => highlightElement(document.querySelector(`[data-row-key='${user.id}']`)),
            onCancel: () => highlightElement(document.querySelector(`[data-row-key='${user.id}']`)),
          });
        };
        if (rowData["status"] === "FormSubmitted") {
          return (
            <Button onClick={handleOpenApplication}>
              {approved ? (
                <div className="green">
                  <FaCheck /> Approved
                </div>
              ) : approved === false ? (
                <div className="red">
                  <FaX /> Not approved
                </div>
              ) : (
                <div style={{ color: "blue" }}>
                  <FaClock /> To do!
                </div>
              )}
            </Button>
          );
        } else return <div style={{ color: "grey" }}>Not submitted</div>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, rowData) => {
        const handleOpenApplication = () => {
          Modal.confirm({
            title: "Approve/Disapprove Application",
            width: "100%",
            icon: null,
            content: <FormIndex displayOnly options={{ user, form_name: rowData.formKey }} />,
            closable: true, // Adds the close button at the top
            maskClosable: true, // Allows the modal to close when clicking outside
            destroyOnClose: true, // Ensures modal content is destroyed on close
            onOk: () => highlightElement(document.querySelector(`[data-row-key='${user.id}']`)),
            onClose: () => highlightElement(document.querySelector(`[data-row-key='${user.id}']`)),
            onCancel: () => highlightElement(document.querySelector(`[data-row-key='${user.id}']`)),
          });
        };
        return (
          <div
            onClick={() => {
              handleOpenApplication();
            }}
          >
            {tableButtons[status]}
          </div>
        );
      },
    },
  ];

  return <Table columns={columns} dataSource={tableData} pagination={false} bordered style={{ marginTop: "20px" }} />;
};

export const UserAIResult = ({ user }) => {
  return (
    <div style={{ padding: "0px", width: "100%" }}>
      <Divider />
      <div style={{ marginTop: "8px" }}>
        <div
          style={{
            fontWeight: "bold",
            fontSize: "16px",
            textTransform: "capitalize",
          }}
        >
          Spam Risk: {user.aispam_spam_risk}, ({user.aispam_spam_risk_percentage}%)
        </div>
        <div style={{ marginTop: "8px" }}>
          <Text type="secondary">Comments:</Text>
          <Text style={{ display: "block" }}>{user.aispam_comments}</Text>
        </div>
        <div style={{ marginTop: "3px" }}>
          <Text type="secondary">{moment(user.aispam_last_ran_on).format("DD MMM YY")}</Text>
        </div>
      </div>
    </div>
  );
};

const UserInfo = ({ user, rawUsers }) => {
  const userInformation = <div></div>;
  return (
    <div style={{ marginBottom: "50px" }}>
      <UserAIResult user={user} />
      <Extra user={user} />
      <UserFormSubmissions user={user} rawUsers={rawUsers} />
    </div>
  );
};

export default UserInfo;
