import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Input, Spin, List } from "antd";
import { useJsApiLoader } from "@react-google-maps/api";
import { SearchOutlined } from "@ant-design/icons";

const libraries = ["places"];

const LocationAutocomplete = ({ value, onComplete, onInput }) => {
  const [address, setAddress] = useState(value || "");
  const [predictions, setPredictions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [placesService, setPlacesService] = useState(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyC9_Osa1oB89IeKG6b0IATP6fPAP9hM5q0",
    libraries,
    version: "weekly",
    googleMapsApiOptions: {
      loading: 'async'
    }
  });

  useEffect(() => {
    if (value !== address) {
      setAddress(value || "");
    }
  }, [value]);

  useEffect(() => {
    if (isLoaded && window.google) {
      setAutocompleteService(new window.google.maps.places.AutocompleteService());
      setPlacesService(new window.google.maps.places.PlacesService(document.createElement('div')));
    }
  }, [isLoaded]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setAddress(value);
    
    if (onInput) {
      onInput(value);
    }

    if (value.length > 2 && autocompleteService) {
      setIsSearching(true);
      
      const searchOptions = {
        input: value,
        componentRestrictions: { country: "us" },
        types: ["address"],
        locationBias: {
          north: 42.886589,
          south: 41.237964,
          west: -73.508142,
          east: -69.928393,
        }
      };

      autocompleteService.getPlacePredictions(searchOptions, (results, status) => {
        setIsSearching(false);
        if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
          setPredictions(results);
        } else {
          setPredictions([]);
        }
      });
    } else {
      setPredictions([]);
    }
  };

  const handleSelectAddress = (placeId) => {
    if (!placesService) return;

    placesService.getDetails({ placeId, fields: ["address_components", "formatted_address", "geometry"] }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
        const formattedAddress = place.formatted_address;
        setAddress(formattedAddress);
        setPredictions([]);
        
        let city = '';
        let zip = '';
        let county = '';
        let details = {
          street_number: '',
          route: '',
          administrative_area_level_1: ''
        };
        
        place.address_components.forEach(component => {
          const types = component.types;
          
          if (types.includes('locality')) {
            city = component.long_name;
          }
          
          if (types.includes('postal_code')) {
            zip = component.long_name;
          }
          
          if (types.includes('administrative_area_level_2')) {
            county = component.long_name.replace(' County', '');
          }
          
          if (types.includes('administrative_area_level_1')) {
            details.administrative_area_level_1 = component.short_name;
          }
          
          if (types.includes('street_number')) {
            details.street_number = component.long_name;
          }
          
          if (types.includes('route')) {
            details.route = component.long_name;
          }
        });
        
        const coords = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        };
        
        if (onComplete) {
          onComplete(formattedAddress, city, zip, county, coords, details);
        }
      }
    });
  };

  if (loadError) {
    return <div>Error loading Google Maps API: {loadError.message}</div>;
  }

  if (!isLoaded) {
    return <Input size="large" placeholder="Loading address search..." disabled prefix={<Spin size="small" />} />;
  }

  return (
    <div className="location-autocomplete" style={{ position: "relative" }}>
      <Input
        size="large"
        value={address}
        onChange={handleInputChange}
        placeholder="Search for a location"
        prefix={isSearching ? <Spin size="small" /> : <SearchOutlined />}
      />
      
      {predictions.length > 0 && (
        <List
          size="small"
          bordered
          style={{
            position: "absolute",
            width: "100%",
            zIndex: 1000,
            backgroundColor: "white",
            maxHeight: "300px",
            overflowY: "auto",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)"
          }}
          dataSource={predictions}
          renderItem={item => (
            <List.Item 
              style={{ cursor: "pointer", padding: "8px 12px" }}
              onClick={() => handleSelectAddress(item.place_id)}
              className="address-suggestion-item"
            >
              {item.description}
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

LocationAutocomplete.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onInput: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default LocationAutocomplete;
