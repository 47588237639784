// lifelineFormConfig.js

import { Button } from "antd";
import { allRelations } from "../../_help/relations";
import fields from "../../fields";
import { incomeQuestionsConfig, showIncomeInfoModal } from "./incomeConfig";
import selectPersonNames from "./helper";
export const formName = "snap";
export const snapArizonaConfig = [
  { type: "heading", title: "Personal Information" },
  fields.first_name,
  fields.last_name,
  fields.dob,
  // fields.preferred_language,
  {
    title: "Social Security Number (SSN)",
    id: "ssn",
    type: "text",
    help_text2: "Please leave it empty if you don't have one.",
  },
  {
    title: "Is your household homeless?",
    id: "homeless",
    required: true,
    type: "radio",
    options: [
      { value: "Yes", text: "Yes" },
      { value: "No", text: "No" },
    ],
  },
  {
    id: "disability",
    title: "Do you have a disability",
    help_text2: "A disability must be federally certified to be considered for SNAP. For example, people who receive SSI have a federally qualified disability.",
    type: "boolean",
    value: false,
  },
  fields.address,
  // fields.zip,
  // fields.city,
  // considering that mailing address will be same as this one
  fields.phone_number,
  fields.email,
  // emergency snap questions are not being asked, question: what default should be used instead?
  {
    title: "Are you a US citizen?",
    id: "us_citizen",
    required: true,
    type: "radio",
    options: [
      { value: "Yes", text: "Yes" },
      { value: "No", text: "No" },
    ],
    help_text2: "Even if you are not a US Citizen, you or other household members may still be eligible.",
  },
  fields.preferred_language,
  fields.gender,
  {
    title: "Ethnicity:",
    id: "ethnicity",
    required: true,
    type: "select",
    options: [
      { value: "Hispanic or Latino", text: "Hispanic or Latino" },
      { value: "Not Hispanic or Latino", text: "Not Hispanic or Latino" },
      { value: "Decline to answer", text: "Decline to answer" },
    ],
  },
  fields.race,
  { type: "heading", title: "Family Information" },
  {
    id: "live_with_others",
    title: "Do other people live with you?",
    snap_id: "Do you live with other people?",
    type: "boolean",
    value: false,
    more: {
      // ask more questions when the answer of the above question is= when
      entryText: "Person living with you",
      when: true,
      limit: 10,
      questions: [
        {
          id: "name",
          title: "Name",
          type: "text",
        },
        {
          id: "gender",
          title: "Gender",
          type: "radio",
          options: [
            { value: "Male", text: "Male" },
            { value: "Female", text: "Female" },
          ],
        },
        {
          id: "date_of_birth",
          title: "Date of Birth",
          type: "date", // Could be "date" if the system supports date formats
        },
        {
          id: "social_security_number",
          title: "Social Security Number (SSN)",
          type: "text",
          text_type:"number",
          help_text2: "Optional",
        },
        {
          id: "relationship_to_applicant",
          title: "Relationship to you?",
          type: "select",
          options: allRelations,
        },
        {
          id: "us_citizen",
          title: "Is this person a US citizen?",
          type: "boolean",
          value: false,
        },
        {
          id: "disability",
          title: "Does this person has a disability",
          help_text2: "A disability must be federally certified to be considered for SNAP. For example, people who receive SSI have a federally qualified disability.",
          type: "boolean",
          value: false,
        },
      ],
    },
  },
  {
    title: "Do you need an EBT card?",
    id: "ebt_card",
    required: true,
    type: "radio",
    options: [
      { value: "Yes", text: "Yes" },
      { value: "No", text: "No" },
    ],
    help_text2: "If you have an EBT card in your name, you do not need a new one. If you click yes, your old card will be cancelled right away.",
  },
  { type: "heading", title: "Income Information" },
  {
    id: "pay_shelter_costs",
    title: "Is the household responsible to pay shelter costs?",
    snap_id: "Shelter costs",
    type: "boolean",
    value: false,
    more: {
      when: true,
      entryText: "Shelter cost",
      limit: 3,
      questions: [
        {
          id: "shelter_cost_details",
          title: "Provide details of your shelter costs:",
          type: "select",
          options: ["Rent", "Mortgage", "Property Taxes", "Home Insurance", "Condo fee", "Other"],
        },
        {
          id: "amount",
          title: "Amount (per month)",
          type: "text",
          text_type:"amount",
          placeholder: "$ _________",
        },
      ],
    },
  },
  {
    id: "pay_utility_costs",
    title: "Is the household responsible to pay utility costs separate from shelter costs?",
    type: "boolean",
    value: false,
    snap_id: "Utility costs",
    more: {
      when: true,
      limit: 1,
      questions: [
        {
          id: "utility_service_options",
          title: "Select all utility and service options applicable to your household:",
          type: "multi-select",
          options: [
            "Heat (oil, gas, electricity or propane, etc.)",
            "Electricity and/or gas (other than heating use); water; sewage; and/or trash collection",
            "Electricity for an air conditioner in the summer",
            "A fee to use an air conditioner in the summer",
            "Phone or cell phone service (including pre-paid)",
          ],
        },
      ],
    },
  },
  {
    title: "Does anyone (including you) recieve any amount/benefits?",
    help_text2: "This is an important question. Please answer it carefully.",
    id: "earning_options",
    required: true,
    type: "boolean",
    value: false,
    more: {
      when: true,
      entryText: "Earning entry",
      limit: 20,
      questions: [
        {
          id: "Person",
          title: "Person earning this amount",
          type: "select",
          options:selectPersonNames
        },
        {
          id: "Income type",
          title: "Income type",
          type: "select",
          help_text2: <><Button className="no-padding no-margin" type="link" onClick={() => showIncomeInfoModal()}>Know more</Button></>,
          options: incomeQuestionsConfig.map((item) => item.title),
        },
        {
          id: "Amount earned (per month)",
          title: "Earnings (per month)",
          type: "text",
          text_type:"amount",
          placeholder: "$ _________",
        },
      ],
    },
  },
  // fields.consent(formName),
];
