import { Button } from "antd";
import React from "react";
import { dateNumberToAmericanTimeSimple } from "../../../../helpers/time";
import moment from "moment";

const Extra = ({ user }) => {
  if (!user.snap_submitted) {
    return <></>;
  }

  async function downloadreportpsi() {
    const requestData = {
      id: user.id,
      form_data: {
        "ClientApplicant Name": user.name,
        "DTA Agency ID if known or Last Four Digits of SSN": user.ssn?.slice(-4),
        "Date of Birth MMDDYYYY": moment( user.dob,"DD-MM-YYYY").format("MM/DD/YYYY"),
        "I allow DTA and the SNAP Outreach partner organization named in Section 3 to share information": true,
        "I allow DTA and the DTA Pathways to Work provider SNAP Path to Work TAFDC Pathways to": true,
        "Signature date_af_date": dateNumberToAmericanTimeSimple(user["snap-started_on"]),
      },
      signature: user.signature,
    };

    try {
      const response = await fetch("https://alistermartin.pythonanywhere.com/generate_psi_pdf", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `psi_report_${user.id}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading PSI PDF:", error);
    }
  }

  return (
    <div>
      <Button type="link" onClick={downloadreportpsi}>
        Download User's PSI PDF
      </Button>
    </div>
  );
};

export default Extra;
