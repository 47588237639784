const fields = {
  first_name: {
    title: "First Name",
    id: "first_name",
    required: true,
    type: "text",
    info: "Please enter your first name.",
  },
  last_name: {
    title: "Last Name",
    id: "last_name",
    required: true,
    type: "text",
    info: "Please enter your last name.",
  },
  dob: {
    title: "Date of Birth",
    id: "dob",
    required: true,
    type: "date",
    date_format: "MM/DD/YYYY",
    info: "Please enter your date of birth in MM/DD/YYYY format.",
  },
  preferred_language: {
    title: "Preferred Language",
    id: "preferred_language",
    required: true,
    type: "select",
    options: [
      { value: "English", text: "English" },
      { value: "Spanish", text: "Spanish" },
      { value: "Other", text: "Other" },
    ],
  },
  ssn_last4: {
    title: "Last 4 digits of Social Security Number (SSN)",
    id: "ssn_last4",
    required: true,
    type: "text",
    info: "Please enter the last 4 digits of your Social Security Number.",
    validate: (val) => {
      if (/^\d{4}$/.test(val)) {
        return true;
      } else {
        throw new Error("Please enter a valid 4-digit SSN.");
      }
    },
  },
  address: {
    title: "Address",
    id: "address",
    required: true,
    type: "text",
    text_type: "address",
    info: "Please enter your street address.",
  },
  city: {
    title: "City",
    id: "city",
    required: true,
    type: "text",
    info: "Please enter your city.",
  },
  zip: {
    title: "Zip Code",
    id: "zip",
    required: true,
    type: "text",
    info: "Please enter your zip code.",
    validate: (val) => {
      if (/^\d{5}$/.test(val)) {
        return true;
      } else {
        throw new Error("Please enter a valid 5-digit zip code.");
      }
    },
  },
  gender: {
    title: "Gender",
    id: "gender",
    required: true,
    type: "radio",
    options: [
      { value: "Male", text: "Male" },
      { value: "Female", text: "Female" },
    ],
  },
  race: {
    title: "Race",
    id: "race",
    required: true,
    type: "select",
    options: [
      {value:"American Indian", text:"American Indian"},
      { value: "Asian", text: "Asian" },
      { value: "Black/African American", text: "Black/African American" },
      { value: "White", text: "White" },
      { value: "Pacific Islander", text: "Pacific Islander" },
      { value: "Decline to answer", text: "Decline to answer" },
    ],
  },
  email: {
    title: "Email address",
    id: "email",
    required: true,
    type: "text",
    info: "Please enter your email address.",
    validate: (val) => {
      try {
        if (val.includes("@")) {
          return true;
        } else {
          throw new Error("Please enter a valid email address.");
        }
      } catch (err) {
        throw new Error("Please enter a valid email address.");
      }
    },
  },
  phone_number: {
    title: "Phone number",
    id: "phone_number",
    required: true,
    type: "text",
    text_type: "number",
    info: "Please enter your phone number.",
    validate: (val) => {
      try {
        const cleanedVal = val.replace(/[^0-9]/g, "").replace(/^1/, "");
        if (cleanedVal.length === 10 && /^\d+$/.test(cleanedVal)) {
          return true;
        } else {
          throw new Error("not valid");
        }
      } catch (err) {
        throw new Error("Please enter a valid 10-digit phone number.");
      }
    },
  },
  consent: (form_name) => ({
    title: "Consent",
    id: `consent-${form_name}`,
    options: [
      {
        value: true,
        text: (
          <>
            I consent to the use and disclosure of my personal information in accordance with{" "}
            <a style={{ textDecoration: "underline" }} href="https://link-health.org/privacy-policy/" target="_blank">
              Link Health's privacy and data sharing policy.
            </a>
          </>
        ),
      },
    ],
    validate: (value) => {
      return value === true;
    },
    required: true,
    type: "checkbox",
  }),
};
// they contain a list of common fields.
export default fields;
