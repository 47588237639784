import React from 'react';
import { Progress } from 'antd';
import styled from 'styled-components';

const MinimalProgressContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 12px;
  width: 150px;
`;

const PercentText = styled.div`
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin-top: 5px;
  color: #1890ff;
`;

const ProgressDisplayer = ({ done = 0, total = 100 }) => {
  const percent = Math.round((done / total) * 100);
  
  return (
    <MinimalProgressContainer>
      <Progress 
        percent={percent} 
        showInfo={false}
        strokeColor={{
          '0%': '#108ee9',
          '100%': '#87d068',
        }}
        strokeWidth={8}
      />
      <PercentText>{percent}% completed</PercentText>
    </MinimalProgressContainer>
  );
};

export default ProgressDisplayer;
