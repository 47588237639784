import React, { useState } from "react";
import { Modal, Button, Checkbox } from "antd";
import snapDtaTermsHtml from "./termsandconditions";

const SignatureModalSnap = ({ setData }) => {
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [consentDTA, setConsentDTA] = useState(false);
  const [consentPathways, setConsentPathways] = useState(false);

  const showTermsModal = () => {
    setIsTermsModalOpen(true);
  };

  const handleTermsModalClose = () => {
    setIsTermsModalOpen(false);
  };

  const handleConsentDTAChange = (e) => {
    setConsentDTA(e.target.checked);
    setData("consentDTA", e.target.checked);
  };

  const handleConsentPathwaysChange = (e) => {
    setConsentPathways(e.target.checked);
    setData("consentPathways", e.target.checked);
  };

  return (
    <div>
      <div className="signature-info-links">
        <p className="consent-statement">
          By signing, you consent to{" "}
          <a className="external-link" href="https://link-health.org/privacy-policy/" target="_blank" rel="noopener noreferrer">
            link Health's privacy and data sharing policy
          </a>
          {" "}and{" "}
          <Button type="link" onClick={showTermsModal} className="info-link">
            SNAP Terms and Conditions.
          </Button>
        </p>
      </div>

      {/* Terms and Conditions Modal */}
      <Modal
        title="Terms and Conditions"
        open={isTermsModalOpen}
        onCancel={handleTermsModalClose}
        footer={null}
        width={800}
        className="terms-modal"
        bodyStyle={{
          padding: "24px",
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: snapDtaTermsHtml }} />
      </Modal>

      {/* Consent checkboxes for the signature modal */}
      <div className="consent-section">
        <div className="consent-checkbox-container">
          <Checkbox required className="consent-checkbox" checked={consentDTA} onChange={handleConsentDTAChange}>
            I consent to DTA and the SNAP Outreach partner sharing information about my benefits and expenses.
          </Checkbox>
        </div>
        <div className="consent-checkbox-container">
          <Checkbox required className="consent-checkbox" checked={consentPathways} onChange={handleConsentPathwaysChange}>
            I consent to DTA and Pathways to Work sharing information about my benefits and employment records.
          </Checkbox>
        </div>
      </div>
    </div>
  );
};

export default SignatureModalSnap;
