import React, { useState, useCallback, useEffect } from "react";
import { Form, Input, Select, Radio, Checkbox, Button, Typography, Steps, message, DatePicker, Divider, Tag, AutoComplete, Modal } from "antd";
import debounce from "lodash/debounce";
import LocationAutocomplete from "./components/goog/LocationAutoComplete";
import { catcher } from "../../components/trycatch/catcher";
import "./form.styles.css";
import dayjs from "dayjs";
import { PlusOutlined, MinusCircleOutlined, DeleteOutlined } from "@ant-design/icons"; // Using Ant Design icons for visual improvement
import { updateUser } from "../../backend/users/set";
import useSize from "../../components/use/useSize";
import SignatureComponent from "./signature";
import ProgressDisplayer from "./components/progress/ProgressDisplayer";
import { ToWords } from "to-words";
import SignatureModalSnap from "./forms/snap/arizona/other/signatureModals";

const toWords = new ToWords({ localeCode: "en-US" });

const { Option } = Select;
const { Step } = Steps;

const FieldRenderer = ({ field, form, displayOnly, formId }) => {
  const { isMobile } = useSize();
  let { title, id, required, type, options, info, validate, date_format = "MM/DD/YYYY", ins, more, help_text2 } = field;

  // Handle options formatting
  console.log("form " + formId);
  if (typeof options === "function") {
    options = options(JSON.parse(localStorage.getItem("form " + formId), form));
  }
  if (Array.isArray(options) && options.length > 0 && typeof options[0] !== "object") {
    options = options.map((option) => ({ label: option, value: option }));
  }

  // State to manage clusters of additional questions
  const [additionalClusters, setAdditionalClusters] = useState(
    more?.questions
      ? [
          more.questions.map((q, i) => ({
            ...q,
            id: `${id}_more_0_${i}_${q.id}`,
          })),
        ]
      : []
  );
  const [clusterCount, setClusterCount] = useState(1); // Start with one cluster
  const clusterLimit = more?.limit || 4; // Default limit to 4 clusters if not provided

  const getValidationRules = () => {
    let message = "This field is required";
    try {
      message = `Please add ${title?.toLowerCase()}`;
    } catch (err) {}
    const rules = [{ required, message }];
    if (validate) {
      rules.push({
        validator: async (_, value) => {
          try {
            await validate(value);
          } catch (error) {
            throw new Error(error.message);
          }
        },
      });
    }
    return rules;
  };

  const formItemProps = {
    key: id,
    label: (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {title}
        {help_text2 && <div style={{ fontSize: "0.85em", color: "#666", marginTop: "4px", fontWeight: "normal" }}>{help_text2}</div>}
      </div>
    ),
    name: id,
    size: "large",
    rules: getValidationRules(),
    style: { pointerEvents: displayOnly ? "none" : "initial" },
  };
  const itemProps = {
    border: displayOnly ? "none !important" : "initial",
  };

  // Handle adding a new cluster of questions
  const handleAddCluster = () => {
    if (clusterCount < clusterLimit) {
      const newCluster = more.questions.map((q, i) => ({
        ...q,
        id: `${id}_more_${clusterCount}_${i}_${q.id}`,
      }));
      setAdditionalClusters([...additionalClusters, newCluster]);
      setClusterCount(clusterCount + 1);
    }
  };

  // Handle removing a specific cluster
  const handleRemoveCluster = (clusterIndex) => {
    if (clusterCount > 1) {
      const updatedClusters = additionalClusters.filter((_, index) => index !== clusterIndex);
      setAdditionalClusters(updatedClusters);
      setClusterCount(clusterCount - 1);
    }
  };

  // Handle removing a cluster with confirmation
  const showRemoveConfirmation = (clusterIndex) => {
    Modal.confirm({
      title: `Remove ${more.entryText ?? "Person"}?`,
      content: `Are you sure you want to remove ${more.entryText ?? "Person"} ${clusterIndex + 1}?`,
      okText: "Yes, Remove",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        handleRemoveCluster(clusterIndex);
      },
    });
  };

  if (type === "text") {
    if (field.text_type === "address") {
      // special case
      return (
        <Form.Item {...formItemProps} style={{ pointerEvents: displayOnly ? "none" : "initial" }}>
          <LocationAutocomplete
            value={form.getFieldValue(id)}
            onComplete={(formattedAddress, city, zip, county, coords, details) => {
              // Update the main address field
              form.setFieldsValue({ [id]: formattedAddress });
            }}
            onInput={(value) => form.setFieldsValue({ [id]: value })}
          />
        </Form.Item>
      );
    }
  }

  switch (type) {
    case "heading":
      return (
        <Typography.Title level={3} key={id} translate="no">
          {title}
        </Typography.Title>
      );

    case "text":
      return (
        <>
          <Form.Item {...formItemProps}>
            {field.suggestions ? (
              <AutoComplete options={field.suggestions.map((s) => ({ value: s }))} placeholder={info || title} {...itemProps} />
            ) : (
              <Input type={["amount", "number"].includes(field.text_type) ? "number" : ""} placeholder={info || title} {...itemProps} />
            )}
          </Form.Item>
          {field.text_type === "amount" && (
            <span style={{ transform: "translateY(-30px)", fontSize: "0.8em", color: "#666", fontWeight: "normal" }}>
              {form.getFieldValue(id) ? `${toWords.convert(Number(form.getFieldValue(id)))} dollars/ Month` : ""}
            </span>
          )}
        </>
      );

    case "textarea":
      return (
        <Form.Item {...formItemProps}>
          <Input.TextArea placeholder={info || title} {...itemProps} />
        </Form.Item>
      );

    case "select":
      return (
        <Form.Item {...formItemProps}>
          <Select {...itemProps} placeholder="Select an option" showSearch filterOption={(input, option) => option?.children?.toLowerCase().includes(input?.toLowerCase())}>
            {options?.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      );

    case "multi-select":
      return (
        <Form.Item {...formItemProps}>
          <Checkbox.Group style={{ width: "100%", display: "flex", flexDirection: "column", gap: "8px" }}>
            {options?.map((option) => (
              <Checkbox key={option.value} value={option.value}>
                {option.text || option.value}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>
      );

    case "radio":
      return (
        <>
          {ins}
          <Form.Item {...formItemProps}>
            <Radio.Group size={isMobile ? "middle" : formItemProps.size} optionType="button" {...itemProps}>
              {options?.map((option) => (
                <Radio.Button key={option.value} value={option.value}>
                  {option.text}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
        </>
      );

    case "checkbox":
      if (options?.length > 0) {
        return (
          <Form.Item {...formItemProps}>
            <Checkbox.Group style={{ display: "flex", flexDirection: "column" }} {...itemProps}>
              {options?.map((option) => (
                <Checkbox key={option.value} value={option.value}>
                  {option.text}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        );
      } else {
        return (
          <Form.Item {...formItemProps} valuePropName="checked">
            <Checkbox {...itemProps}>{title}</Checkbox>
          </Form.Item>
        );
      }

    case "date":
      return (
        <Form.Item
          {...formItemProps}
          getValueProps={(value) => ({
            value: value ? dayjs(value, date_format) : null,
          })}
          getValueFromEvent={(value) => (value ? value.format(date_format) : null)}
        >
          <DatePicker style={{ width: "100%" }} {...itemProps} placeholder={info || title} format={date_format} />
        </Form.Item>
      );

    case "boolean":
      return (
        <>
          {/* Main Boolean Field */}
          <Form.Item {...formItemProps}>
            <Radio.Group {...itemProps} onChange={(e) => form.setFieldsValue({ [id]: e.target.value })}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>

          {/* Render clusters of questions */}
          {more && form.getFieldValue(id) === more.when && (
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              {additionalClusters.map((cluster, clusterIndex) => (
                <div
                  key={`cluster_${clusterIndex}`}
                  style={{
                    marginBottom: "20px",
                    padding: "16px",
                    border: "1px solid #d9d9d9",
                    borderRadius: "8px",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.06)",
                    backgroundColor: "#fafafa",
                    transition: "all 0.3s ease",
                  }}
                >
                  {clusterLimit !== 1 && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "16px",
                          borderBottom: "1px solid #f0f0f0",
                          paddingBottom: "12px",
                        }}
                      >
                        <Typography.Title
                          level={4}
                          style={{
                            margin: "0",
                            color: "#1890ff",
                            fontWeight: "500",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {more.entryText ?? "Entry"} ({clusterIndex + 1})
                          {clusterCount > 1 && (
                            <DeleteOutlined
                              style={{
                                fontSize: "18px",
                                color: "#ff4d4f",
                                cursor: "pointer",
                                marginLeft: "12px",
                                transition: "all 0.3s",
                              }}
                              onClick={() => showRemoveConfirmation(clusterIndex)}
                            />
                          )}
                        </Typography.Title>
                      </div>
                    </div>
                  )}

                  <div style={{ padding: "0 8px" }}>
                    {cluster.map((question) => (
                      <FieldRenderer key={question.id} field={question} form={form} displayOnly={displayOnly} formId={formId} />
                    ))}
                  </div>
                </div>
              ))}

              {/* Full-width Add More button */}
              <Button
                type="dashed"
                icon={<PlusOutlined />}
                onClick={handleAddCluster}
                disabled={clusterCount >= clusterLimit || displayOnly}
                style={{
                  width: "100%",
                  height: "45px",
                  marginTop: "16px",
                  marginBottom: "20px",
                  borderRadius: "6px",
                  fontSize: "16px",
                  display: clusterLimit === 1 ? "none" : "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderColor: "#1890ff",
                  color: "#1890ff",
                }}
              >
                <span style={{ marginLeft: "8px" }}>Add another {more.entryText ?? "Entry"}</span>
              </Button>

              {clusterCount >= clusterLimit && clusterLimit !== 1 && (
                <div
                  style={{
                    textAlign: "center",
                    color: "#ff4d4f",
                    fontSize: "14px",
                    marginBottom: "10px",
                  }}
                >
                  Maximum limit of {clusterLimit} {more.entryText ?? "entries"} reached
                </div>
              )}
            </div>
          )}
        </>
      );

    default:
      return null;
  }
};

const FormComponent = ({ id, uuid, formData, displayOnly = false, initialValues = {}, onSubmit, displayThanksOnSubmit = false, displaySteps = true }) => {
  const [form] = Form.useForm();
  const [removeFilled, setRemoveFilled] = useState(undefined);
  const [signature, setSignature] = useState(null);
  const [extraData, setExtraData] = useState({});
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(() => {
    if (displayOnly) return initialValues;
    const savedValues = localStorage.getItem("form " + id);
    console.log("Saved values", savedValues, "initial values", initialValues);
    if (savedValues) {
      return { ...JSON.parse(savedValues) };
    }
    return initialValues;
  });

  const [currentStep, setCurrentStep] = useState(0);

  // Calculate total questions and completed questions
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [completedQuestions, setCompletedQuestions] = useState(0);

  // Count total questions including nested ones in "more" sections
  useEffect(() => {
    let count = 0;

    const countQuestionsInField = (field) => {
      // Don't count headings as questions
      if (!["heading", "devtext"].includes(field.type)) {
        count++;
      }

      // // Count additional questions in "more" sections
      // if (field.more?.questions) {
      //   field.more.questions.forEach(question => {
      //     countQuestionsInField(question);
      //   });
      // }
    };

    formData.forEach((field) => {
      countQuestionsInField(field);
    });

    setTotalQuestions(count);
  }, [formData]);

  // Calculate completed questions whenever form values change
  useEffect(() => {
    let completed = 0;

    const checkFieldCompletion = (field) => {
      if (!["heading", "devtext"].includes(field.type) && formValues[field.id] !== undefined && formValues[field.id] !== "") {
        completed++;
      }

      // // Check completion of nested questions in "more" sections
      // if (field.more?.questions && formValues[field.id] === field.more.when) {
      //   // For each cluster that might exist
      //   for (let i = 0; i < (field.more.limit || 4); i++) {
      //     field.more.questions.forEach((question, qIndex) => {
      //       const nestedId = `${field.id}_more_${i}_${qIndex}_${question.id}`;
      //       if (formValues[nestedId] !== undefined && formValues[nestedId] !== '') {
      //         completed++;
      //       }
      //     });
      //   }
      // }
    };

    formData.forEach((field) => {
      checkFieldCompletion(field);
    });

    setCompletedQuestions(completed);
  }, [formValues, formData]);

  useEffect(() => {
    if (initialValues && initialValues[`${id}-started_on`]) {
    } else updateUser(uuid, { [`${id}-started_on`]: Date.now() });
  }, []);

  const debouncedUpdateFormValues = useCallback(
    debounce((values) => {
      setFormValues((prevValues) => {
        const newValues = { ...prevValues, ...values };
        localStorage.setItem("form " + id, JSON.stringify(newValues));
        console.log("Form saved", newValues);
        return newValues;
      });
    }, 300),
    []
  );

  const debouncedUpdateUser = useCallback(
    debounce((values) => {
      if (uuid) {
        updateUser(`raw-${uuid}-${id}`, { ...values, [`${id}-last_saved_on`]: Date.now() }, "Raw");
        console.log("User synced", values);
      } else {
        console.log("Uuid not found");
      }
    }, 300),
    []
  );

  useEffect(() => {
    form.setFieldsValue(formValues);
  }, [formValues, form]);

  useEffect(() => {
    if (!displayOnly)
      formData.forEach((field, index) => {
        if (initialValues[field.id] !== undefined) {
          return setRemoveFilled(true);
        }
      });
  }, [formData]);
  const handleFormChange = (_, values) => {
    debouncedUpdateFormValues(values);
    debouncedUpdateUser(values);
  };

  const handleNext = async () => {
    try {
      const currentFields = await form.validateFields();
      setFormValues((prevValues) => ({ ...prevValues, ...currentFields }));
      setCurrentStep(currentStep + 1);
      // Scroll to the top of the form container
      document.querySelector(".form-container").scrollIntoView({ behavior: "smooth", block: "start" });
    } catch (error) {
      message.error("Please fill out the required fields.");
    }
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
    // Scroll to the top of the form container
    document.querySelector(".form-container").scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleFinish = async () => {
    await catcher(
      async () => {
        const currentFields = await form.validateFields();
        const allValues = { ...formValues, ...currentFields };
        if (id === "snap") {
          if (!signature) {
            message.error("Please provide a signature");
            return;
          }
          allValues.signature = signature;
        }
        await onSubmit(allValues);
        if (displayThanksOnSubmit) {
          message.success("Form submitted successfully!");
        }
      },
      { setLoading, err_msg: "Please fill out the required fields correctly." }
    );
  };

  const renderFormFields = () => {
    let steps = [];
    let currentPanel = [];
    let currentHeading = null;
    let panelIndex = 0;

    formData.forEach((field, index) => {
      if (initialValues[field.id] !== undefined) {
        if (removeFilled === true) return;
      }
      if (typeof field.render_if === "function") {
        if (!field.render_if(formValues)) {
          return;
        }
      }
      if (!displaySteps && index > 0 && field.type === "heading") return;
      if (field.type === "heading") {
        if (currentHeading) {
          steps.push({
            title: currentHeading.title,
            content: currentPanel,
            key: panelIndex,
          });
          currentPanel = [];
          panelIndex++;
        }
        currentHeading = field;
      } else {
        currentPanel.push(<FieldRenderer key={field.id} field={field} form={form} displayOnly={displayOnly} formId={id} />);
      }
    });

    if (currentHeading) {
      steps.push({
        title: currentHeading.title,
        content: currentPanel,
        key: panelIndex,
      });
    }

    return steps;
  };

  const steps = renderFormFields();

  const sig = id === "snap" && !(currentStep < steps.length - 1) && (
    <SignatureComponent initialValue={signature} key={signature} setSignature={setSignature} extraComponent={<SignatureModalSnap setData={setExtraData} />} />
  );

  return (
    <div className="form-container">
      {/* Add ProgressDisplayer component */}
      {!displayOnly && <ProgressDisplayer done={completedQuestions} total={totalQuestions} position="bottom" description="Form fields completed" />}

      <Steps current={currentStep} className={steps.length === 1 ? "hidden" : ""}>
        {steps.map((step) => (
          <Step key={step.key} title={step.title} />
        ))}
      </Steps>
      <div id="other-settings">
        {removeFilled !== undefined ? (
          <div className="remove-filled">
            <Checkbox
              style={{ marginTop: "15px", marginLeft: "5px" }}
              checked={removeFilled}
              onChange={(e) => {
                setRemoveFilled(e.target.checked);
              }}
            >
              Remove filled fields
            </Checkbox>
          </div>
        ) : null}
      </div>
      <Form form={form} size="large" layout="vertical" onValuesChange={handleFormChange} initialValues={formValues} requiredMark={false} className="form">
        <div>{steps[currentStep]?.content}</div>
        {sig}
        <Form.Item style={{ width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "flex-end", gap: 10, marginTop: "10px" }}>
            {currentStep > 0 && (
              <Button onClick={handlePrevious} style={{ marginRight: "10px" }}>
                Previous
              </Button>
            )}
            {currentStep < steps.length - 1 ? (
              <Button onClick={handleNext} type="primary">
                Next
              </Button>
            ) : (
              <>
                {displayOnly ? null : (
                  // <Button>Ok</Button>
                  <div>
                    <Button type="primary" onClick={handleFinish} loading={loading}>
                      Submit
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FormComponent;
