export const allRelations = [
  "Spouse",
  "Father",
  "Son",
  "Sister",
  "Grandfather",
  "Grandson",
  "Mother",
  "Daughter",
  "Brother",
  "Grandmother",
  "Granddaughter",
  "Other"
]

  