import React, { useRef, useState } from "react";
import SignaturePad from "react-signature-canvas";
import { Modal, Button, Divider, Input } from "antd";
import { FaSignature } from "react-icons/fa6";
import "./signature.css";

const SignatureComponent = ({ initialValue, setSignature, extraComponent }) => {
  const sigPadRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [tempSignature, setTempSignature] = useState(initialValue);

  const isSourceClinic = () => {
    const source = localStorage.getItem("entrance_source");
    return source === "clinic";
  };

  const handleBlur = () => {
    setSignature(tempSignature);
  };

  const handleClear = () => {
    sigPadRef.current.clear();
  };

  const handleSave = () => {
    if (sigPadRef.current.isEmpty()) {
      alert("Please provide a signature");
      return;
    }
    // Save signature as base64 string
    const dataURL = sigPadRef.current.toDataURL();
    setSignature(dataURL);
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showInfoModal = () => {
    setIsInfoModalOpen(true);
  };

  const handleInfoModalClose = () => {
    setIsInfoModalOpen(false);
  };

  return (
    <div className="signature-container">
      <div>
        <Divider />
        <h3 className="signature-title">Digital Signature</h3>
        
        <Button type="link" onClick={showInfoModal} className="info-link">
          Why we require your signature?
        </Button>

        {extraComponent && extraComponent}

        {isSourceClinic() ? (
          <Input 
            value={tempSignature}
            placeholder="Please enter your full name"
            onChange={(e) => setTempSignature(e.target.value)}
            onBlur={handleBlur}
          />
        ) : (
          <>
            {initialValue ? (
              <div className="signature-preview">
                <div className="signature-status">
                  <span className="signature-check">
                    <span>✓</span>
                    <span>Signature submitted</span>
                  </span>
                  <Button type="primary" size="small" onClick={showModal} className="edit-button">
                    Edit
                  </Button>
                </div>
                <img src={initialValue} alt="Signature" className="signature-image" />
              </div>
            ) : (
              <Button type="primary" onClick={showModal} icon={<FaSignature />} className="sign-button">
                Sign Here
              </Button>
            )}
          </>
        )}
      </div>

      <Modal
        title="Sign your application"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="clear" onClick={handleClear}>
            Clear
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="save" type="primary" onClick={handleSave}>
            Save
          </Button>,
        ]}
        width="100%"
        style={{ maxWidth: "550px" }}
      >
        <div className="signature-pad-container">
          <SignaturePad
            ref={sigPadRef}
            canvasProps={{
              className: "signature-canvas",
              width: window.innerWidth > 500 ? 500 : window.innerWidth - 40,
              height: 200,
            }}
          />
        </div>
      </Modal>

      {/* Info Modal - Moved from signatureModals.js */}
      <Modal
        title={null}
        open={isInfoModalOpen}
        onCancel={handleInfoModalClose}
        footer={null}
        width={600}
        className="info-modal"
        bodyStyle={{
          padding: "0",
          maxHeight: "90vh",
          overflowY: "auto",
        }}
        closeIcon={
          <Button
            type="text"
            style={{
              position: "absolute",
              right: 16,
              top: 16,
              zIndex: 1,
              background: "white",
              boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
            }}
          >
            ✕
          </Button>
        }
      >
        <div style={{ padding: "24px" }}>
          <h2
            style={{
              fontSize: "24px",
              marginBottom: "24px",
              textAlign: "center",
              borderBottom: "1px solid #f0f0f0",
              paddingBottom: "16px",
            }}
          >
            Why We Need Your Signature
          </h2>

          <div style={{ marginBottom: "24px" }}>
            <p className="info-text">We need your signature to update the Permission to Share Information (PSI) document, which allows us to:</p>
            <ul className="info-list">
              <li className="info-list-item">Share information about your DTA benefits with authorized organizations</li>
              <li className="info-list-item">Help process your benefits efficiently</li>
              <li className="info-list-item">Maintain compliance with DTA requirements</li>
            </ul>
            <p
              style={{
                fontSize: "14px",
                color: "#666",
                backgroundColor: "#f5f5f5",
                padding: "12px",
                borderRadius: "6px",
              }}
            >
              You can view the complete PSI document{" "}
              <a href="#" style={{ textDecoration: "underline" }}>
                here
              </a>
              .
            </p>
          </div>

          <div className="important-details">
            <h3>Important Details</h3>
            <div className="details-box">
              <ul className="details-list">
                <li>This form is valid for two years from the date of signature</li>
                <li>You can revoke this permission at any time</li>
                <li>All information shared is protected and confidential</li>
              </ul>
            </div>
          </div>

          <div style={{ borderTop: "1px solid #f0f0f0", paddingTop: "24px" }}>
            <p style={{ fontSize: "12px", color: "#666", marginBottom: "8px" }}>This institution is an equal opportunity provider.</p>
            <p style={{ fontSize: "12px", color: "#666" }}>
              We must not discriminate due to race, color, national origin, sex (including gender identity and sexual orientation), disability, age, or reprisal or retaliation for prior civil rights
              activity.
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SignatureComponent;
