const selectPersonNames = (d) => {
  try {
    const options = [];
    options.push(`${d.first_name} ${d.last_name}`);
    Object.keys(d).forEach((key) => {
      if (key.includes("_0_name")&&key.includes("live_with_others_more_")) {
        options.push(`${d[key]}`);
      }
    });
    return options;
  } catch (e) {
    return ["error"];
  }
};

export default selectPersonNames;
